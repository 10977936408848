export enum OtpSentErrMsg {
  EmailOtpSent = 'OTP email sent.',
  EmailOtpSentRecently = 'Previous email code sent too recently.',
  SmsOtpSent = 'OTP sms sent.',
  SmsOtpSentRecently = 'Previous sms code sent too recently.',
  IncorrectOtp = 'Incorrect OTP.',
}
export enum OtpChannel {
  Email,
  Sms,
  Unknown,
}
export const errMsgToOtpChannel: Record<OtpSentErrMsg | string, OtpChannel | undefined> = {
  [OtpSentErrMsg.EmailOtpSent]: OtpChannel.Email,
  [OtpSentErrMsg.EmailOtpSentRecently]: OtpChannel.Email,
  [OtpSentErrMsg.SmsOtpSent]: OtpChannel.Sms,
  [OtpSentErrMsg.SmsOtpSentRecently]: OtpChannel.Sms,
  [OtpSentErrMsg.IncorrectOtp]: OtpChannel.Unknown,
};
