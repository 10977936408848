import { Box, Grow } from '@mui/material';
import type { FC } from 'react';
import GradientSavvyLogo from '../logo/gradient-savvy-logo';

type SplashScreenProps = {
  growAnimationDuration?: number;
};

const DEFAULT_GROW_ANIMATION_DURATION = 1500;

export const SplashScreen: FC<SplashScreenProps> = (props) => {
  const { growAnimationDuration = DEFAULT_GROW_ANIMATION_DURATION } = props;

  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        justifyContent: 'center',
        left: 0,
        p: 3,
        position: 'fixed',
        top: 0,
        width: '100vw',
      }}
    >
      <Box
        sx={{
          width: 256,
        }}
      >
        <Grow in={true} timeout={growAnimationDuration}>
          <div>
            <GradientSavvyLogo />
          </div>
        </Grow>
      </Box>
    </Box>
  );
};
