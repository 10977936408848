import { JWTContextUser } from 'shared/api/user-api';

declare const process: {
  env: {
    NODE_ENV: 'development' | 'production' | 'test' | null | undefined;
  };
};

const SHARED_USER_IDS = [
  '230', // Heather Lee
  '137', // Cooper Hudson
  '142', // Allison Chan
  '2014', // Marco Garcia
  '123', // Retool Admin
  '2080', // Ops team admin
  '581', // Product team admin
];

export function isSharedEmployeeAccount(user: JWTContextUser): boolean {
  const id = user?.id;
  if (id == null) {
    return false;
  } else if (process.env.NODE_ENV !== 'production') {
    return false; // We don't share dev or test accounts
  }
  return SHARED_USER_IDS.includes(id);
}
