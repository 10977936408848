import { ILocalStorage } from './i-local-storage';
import { StorageKey } from './key-consts';

export const LocalStorage: ILocalStorage = {
  async getItem(key: StorageKey) {
    return localStorage.getItem(key);
  },
  async setItem(key: StorageKey, value: string) {
    localStorage.setItem(key, value);
  },
  async removeItem(key: StorageKey) {
    localStorage.removeItem(key);
  },

  getItemSync_WebOnly(key: StorageKey) {
    return localStorage.getItem(key);
  },
  setItemSync_WebOnly(key: StorageKey, value: string) {
    localStorage.setItem(key, value);
  },
  removeItemSync_WebOnly(key: StorageKey) {
    localStorage.removeItem(key);
  },
};
