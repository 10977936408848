import { ApolloClient } from '@apollo/client';
import { Platform } from 'react-native';
import { ADVISOR_IMPERSONATE_CLIENT_ID_KEY } from 'shared/utils/advisor-utils';
import { removeSalesforceAccessToken } from 'shared/utils/salesforce';
import { LocalStorage } from 'shared/utils/local-storage';

export const clearUserInfo = async (apolloClient: ApolloClient<object>) => {
  await LocalStorage.removeItem('accessToken');
  if (Platform.OS === 'web') {
    removeSalesforceAccessToken();
    sessionStorage.removeItem(ADVISOR_IMPERSONATE_CLIENT_ID_KEY);
  }
  apolloClient
    .clearStore()
    .then(() => {
      // Do nothing
    })
    .catch((e) => {
      console.error(`JWT / Auth Error - error when clearing apollo store ${e}`);
    });
};

export async function isLoggedIn(): Promise<boolean> {
  const accessToken = await LocalStorage.getItem('accessToken');
  return !!accessToken && !isExpiredJWT(accessToken);
}

type JWT = {
  exp: number;
};
export function isExpiredJWT(token: string | null) {
  try {
    if (!token) {
      return false;
    }
    const parsedJWT = JSON.parse(atob(token.split('.')[1])) as JWT;
    return parsedJWT.exp < Math.round(new Date().getTime() / 1000);
  } catch (e) {
    console.error(`JWT / Auth Error - error when parsing for expired JWT ${JSON.stringify(e)}`);
    return true;
  }
}
