import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  ServerError,
  ServerParseError,
} from '@apollo/client';
import { SALESFORCE_ACCESS_TOKEN_EXPIRATION, SALESFORCE_ACCESS_TOKEN_KEY } from 'shared/utils/salesforce';
import { ResultOf, VariablesOf, graphql } from 'shared/gql';
import { Platform } from 'react-native';

export const ReAuthWithSalesforceMutation = graphql(/* GraphQL */ `
  mutation GenerateSalesforceAccessToken($input: GenerateSalesforceAccessTokenInput!) {
    generateSalesforceAccessToken(input: $input) {
      accessToken
    }
  }
`);

function redirectToSalesforceOAuth() {
  window.location.href = '/advisor/dashboard/auth';
}

type GenerateSalesforceAccessToken = (
  options?:
    | MutationFunctionOptions<
        ResultOf<typeof ReAuthWithSalesforceMutation>,
        VariablesOf<typeof ReAuthWithSalesforceMutation>,
        DefaultContext,
        ApolloCache<unknown>
      >
    | undefined
) => Promise<FetchResult<ResultOf<typeof ReAuthWithSalesforceMutation>>>;

export function reAuthWithSalesforce(generateSalesforceAccessToken: GenerateSalesforceAccessToken) {
  if (Platform.OS !== 'web') {
    return;
  }
  generateSalesforceAccessToken({
    variables: {
      input: {},
    },
  })
    .then(({ errors, data }) => {
      if (errors || data?.generateSalesforceAccessToken == null) {
        console.error('Error fetching new access token: ', { errors });
        redirectToSalesforceOAuth();
      } else {
        setSalesforceAccessToken(data.generateSalesforceAccessToken.accessToken);
        window.location.reload();
      }
    })
    .catch((err) => {
      // If the network is down, reload the page instead of redirecting to Salesforce auth
      if (err.message.includes('NetworkError') || err.message.includes('Load failed')) {
        window.location.reload();
      } else {
        console.error('Error fetching new access token: ', { err });
        redirectToSalesforceOAuth();
      }
    });
}

export function getSalesforceAccessToken() {
  if (Platform.OS !== 'web') {
    return;
  }
  // localStorage is not available in SSR
  return typeof localStorage !== 'undefined' ? window.localStorage.getItem(SALESFORCE_ACCESS_TOKEN_KEY) : null;
}

export function removeSalesforceAccessToken() {
  if (Platform.OS !== 'web') {
    return;
  }
  window.localStorage.removeItem(SALESFORCE_ACCESS_TOKEN_KEY);
  window.localStorage.removeItem(SALESFORCE_ACCESS_TOKEN_EXPIRATION);
}

export function setSalesforceAccessToken(accessToken: string) {
  if (Platform.OS !== 'web') {
    return;
  }
  window.localStorage.setItem(SALESFORCE_ACCESS_TOKEN_KEY, accessToken);
  setSalesforceTokenExpiration();
}

function setSalesforceTokenExpiration() {
  // Salesforce Access Tokens typically expire in 2 hours (7,200 seconds), but this value is not guaranteed to be static—Salesforce could change it at any time with no warning.
  const expirationTime = new Date().getTime() + 30 * 60 * 1000; // 30min in milliseconds

  window.localStorage.setItem(SALESFORCE_ACCESS_TOKEN_EXPIRATION, expirationTime.toString());
}

export function isServerError(error: Error | ServerParseError | ServerError): error is ServerError {
  return error && (error as ServerError).statusCode !== undefined;
}
