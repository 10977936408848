import type { Direction, Shadows, Theme } from '@mui/material';
import { createTheme as createMuiTheme, responsiveFontSizes } from '@mui/material/styles';
import { ThemeOptions, filledInputClasses } from '@mui/material';
import { Typography } from './types';

const baseMuiTheme = createMuiTheme();

export const sidebarWidth = '256px';

export const advisorDashboardWidth = '1440px ! important';
export const serviceCalendarDashboardWidth = '1200px';
export const advisorSideBarWidth = '320px';

export const inputTextHeight = '1.4375em';

// Colors
export const neutral = {
  0: '#FFFFFF',
  50: '#FAFBFB',
  100: '#F4F5F6',
  200: '#E8EAED',
  300: '#D1D5DB',
  400: '#9EA6B3',
  500: '#848C9A',
  600: '#6A7281',
  700: '#515767',
  800: '#383D4D',
  900: '#1D2334',
};

export const backgroundColors = {
  contrast: '#FAFBFB',
  default: '#FFFFFF',
  info: '#F6FBFE',
  light: '#F9F9FE',
};

export const dividerColor = '#E8EAED';

export const primaryColors = {
  main: '#175242',
  light: '#457568',
  dark: '#0E3128',
  gold: '#C7BCA1',
  contrastText: '#FFFFFF',
};

export const financialAccountIconColors = {
  bankAccount: '#9078C8',
  companyEquity: '#6FCCDF',
  investmentAccount: '#B06FC4',
  otherAssets: '#67B3AC',
  liability: '#D97C75',
  realEstateAsset: '#6BC0F4',
};

export const greyIconsColor = '#6B7280';

export const assetCategoryColors = {
  equity: '#175242',
  globalEquity: '#C7BCA1',
  alternatives: '#F19E70',
  fixedIncome: '#A98EB1',
  cash: '#D79F32',
  multiAssetStrategy: '#C7BCA1',
};

export const graphColors = {
  linearGradient: '#1752424D',
  primaryLinearGradientTop: '#B1C4BD',
  primaryDateRangeOptionsBackground: '#D8DFDD',
  totalValue: '#175242',
  netInvested: '#2C9075',
  tooltipBackground: '#1752421A',
  accountGradient: '#6B484D33',
  accountTotalValue: '#6B484D',
  accountNetInvested: '#C06F74',
  accountTooltipBackground: '#6B484D0D',
  accountTooltipBackgroundOpaque: '#6B484D',
  grayGrid: '#E8EAED',
};

export const mobileToggleColors = {
  netWorth: {
    text: neutral[0],
    active: '#346053',
    background: '#4F7B6F',
  },
  portfolio: {
    text: '#0E392E',
    active: '#D7E0DC',
    background: '#EDF1EE',
  },
  account: {
    text: '#6B484D',
    active: '#EBE4E5',
    background: '#F9F6F7',
  },
};

export const secondaryColors = {
  main: '#10B981',
  mainLowOpacity: 'rgba(16, 185, 129, 0.08)',
  light: '#3FC79A',
  dark: '#0B815A',
  contrastText: '#FFFFFF',
};

export const successColors = {
  main: '#14B8A6',
  light: '#43C6B7',
  dark: '#0E8074',
  contrastText: '#FFFFFF',
  lightGreen: '#FAFEFB',
  darkGreen: '#10B981',
  alertContent: '#084A42',
  alertBackground: '#E7F8F6',
};

export const infoColors = {
  main: '#2196F3',
  light: '#64B6F7',
  dark: '#0B79D0',
  contrastText: '#FFFFFF',
  alertContent: '#284963',
  alertBackground: '#EFF8FE',
};

export const warningColors = {
  main: '#FFB020',
  light: '#FFBF4C',
  dark: '#B27B16',
  contrastText: '#FFFFFF',
};

export const errorColors = {
  main: '#D14343',
  light: '#DA6868',
  dark: '#922E2E',
  contrastText: '#FFFFFF',
  background: '#FAECEC',
  alertBackground: '#FAECEC',
};

export const textColors = {
  primary: '#1D2334',
  charcoal: '#383D4D',
  secondary: '#6A7281',
  disabled: 'rgba(132, 140, 154, 0.5)',
};

export const goalsColors = {
  on_track: '#64B6F7',
  off_track: textColors.primary,
  off_track_tooltip: neutral[300],
  at_risk: '#FFB020',
  completed: textColors.secondary,
};

export const financialOutlookColors = {
  poor: errorColors.dark,
  hover_poor: '#f7e7e7',
  not_good: errorColors.light,
  hover_not_good: '#faecec',
  neutral: neutral[400],
  hover_neutral: '#f5f6f7',
  good: secondaryColors.main,
  hover_good: '#e3fdf4',
  great: secondaryColors.dark,
  hover_great: '#ddfcf2',
};

export const globalCreateColors = {
  lightGreen: '#E5F6FA',
  darkGreen: '#38818D',
  lightPurple: '#E9EAF5',
  darkPurple: '#2A358E',
};

export const advisorLoginAsBannerColor = '#EDF8FF';

export const actionColors = {
  active: neutral[500],
  focus: 'rgba(55, 65, 81, 0.12)',
  hover: 'rgba(55, 65, 81, 0.04)',
  selected: 'rgba(55, 65, 81, 0.08)',
  disabledBackground: 'rgba(55, 65, 81, 0.12)',
  disabled: 'rgba(55, 65, 81, 0.26)',
};

export const aiColors = {
  background: '#F1F2FF',
  stroke: '#B9BDDF',
  text: '#4655CE',
  textDark: '#0044C8',
};

export const marketingColors = {
  hover: '#00000026',
  menuHover: '#1D23340F',
  maroon: '#6B484D',
  deepBlue: '#095972',
  goldenYellow: '#D79F32',
};

export const advisorDashNavCssVars = {
  '--nav-bg': backgroundColors.default,
  '--nav-color': textColors.primary,
  '--nav-divider-color': dividerColor,
  '--nav-border-color': neutral[100],
  '--nav-logo-border': neutral[100],
  '--nav-section-title-color': neutral[400],
  '--nav-item-color': textColors.secondary,
  '--nav-item-hover-bg': actionColors.hover,
  '--nav-item-active-bg': actionColors.selected,
  '--nav-item-active-color': textColors.primary,
  '--nav-item-disabled-color': neutral[400],
  '--nav-item-icon-color': neutral[400],
  '--nav-item-icon-active-color': primaryColors.main,
  '--nav-item-icon-disabled-color': neutral[400],
  '--nav-item-chevron-color': neutral[400],
  '--nav-scrollbar-color': neutral[900],
};

export const shadows: Shadows = [
  'none',
  '0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)',
  '0px 1px 2px rgba(100, 116, 139, 0.12)',
  '0px 1px 4px rgba(100, 116, 139, 0.12)',
  '0px 1px 5px rgba(100, 116, 139, 0.12)',
  '0px 1px 6px rgba(100, 116, 139, 0.12)',
  '0px 2px 6px rgba(100, 116, 139, 0.12)',
  '0px 3px 6px rgba(100, 116, 139, 0.12)',
  '0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(100, 116, 139, 0.12)',
  '0px 5px 12px rgba(100, 116, 139, 0.12)',
  '0px 5px 14px rgba(100, 116, 139, 0.12)',
  '0px 5px 15px rgba(100, 116, 139, 0.12)',
  '0px 6px 15px rgba(100, 116, 139, 0.12)',
  '0px 7px 15px rgba(100, 116, 139, 0.12)',
  '0px 8px 15px rgba(100, 116, 139, 0.12)',
  '0px 9px 15px rgba(100, 116, 139, 0.12)',
  '0px 10px 15px rgba(100, 116, 139, 0.12)',
  '0px 12px 22px -8px rgba(100, 116, 139, 0.25)',
  '0px 13px 22px -8px rgba(100, 116, 139, 0.25)',
  '0px 14px 24px -8px rgba(100, 116, 139, 0.25)',
  '0px 10px 10px rgba(31, 41, 55, 0.04), 0px 20px 25px rgba(31, 41, 55, 0.1)',
  '0px 25px 50px rgba(100, 116, 139, 0.25)',
  '0px 25px 50px rgba(100, 116, 139, 0.25)',
  '0px 25px 50px rgba(100, 116, 139, 0.25)',
  '0px 25px 50px rgba(100, 116, 139, 0.25)',
];

export const filledInputStyleOverrides = {
  root: {
    borderColor: neutral[200],
    backgroundColor: 'transparent' as 'transparent',
    borderRadius: 8,
    borderStyle: 'solid' as 'solid',
    borderWidth: 1,
    overflow: 'hidden' as 'hidden',
    transition: baseMuiTheme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: actionColors.hover,
    },
    [`&.${filledInputClasses.disabled}`]: {
      backgroundColor: 'transparent',
    },
    [`&.${filledInputClasses.focused}`]: {
      backgroundColor: 'transparent',
      borderColor: primaryColors.main,
      boxShadow: `${primaryColors.main} 0 0 0 1px`,
    },
    [`&.${filledInputClasses.error}`]: {
      borderColor: errorColors.main,
    },
    [`&.${filledInputClasses.error}.${filledInputClasses.focused}`]: {
      boxShadow: `${errorColors.main} 0 0 0 1px`,
    },
    [`&.${filledInputClasses.inputHiddenLabel}`]: {
      paddingTop: '4px',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
  },
};

export const buttonStyleOverrides = {
  root: {
    textTransform: 'none',
    borderRadius: '8px',
  },
  sizeSmall: {
    paddingBottom: 6,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 6,
    borderRadius: 8,
  },
  sizeMedium: {
    paddingBottom: 8,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 8,
    borderRadius: 8,
  },
  sizeLarge: {
    paddingBottom: 11,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 11,
    borderRadius: 8,
  },
  textSizeSmall: {
    padding: '7px 12px',
  },
  textSizeMedium: {
    padding: '9px 16px',
  },
  textSizeLarge: {
    padding: '12px 16px',
  },
  iconSizeSmall: {
    padding: '6px 4px',
  },
  containedSecondary: {
    color: textColors.primary,
    border: `1px solid ${neutral[300]}`,
    '&:hover': {
      backgroundColor: backgroundColors.contrast,
      borderColor: neutral[300],
    },
  },
  textSecondary: {
    color: textColors.primary,
    '&:hover': {
      backgroundColor: backgroundColors.contrast,
    },
  },
  outlinedSecondary: {
    color: textColors.primary,
    border: `1px solid ${neutral[300]}`,
    '&:hover': {
      backgroundColor: backgroundColors.contrast,
      borderColor: neutral[300],
    },
  },
};

export const typography: Typography = {
  fontFamily:
    '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
  fontWeightBold: 600,
  button: {
    fontWeight: '600',
  },
  body1: {
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: 1.5,
  },
  body2: {
    fontSize: '0.875rem',
    fontWeight: '400',
    lineHeight: 1.57,
  },
  subtitle1: {
    fontSize: '1rem',
    fontWeight: '600',
    lineHeight: 1.75,
  },
  subtitle2: {
    fontSize: '0.875rem',
    fontWeight: '600',
    lineHeight: 1.57,
  },
  overline: {
    fontSize: '0.75rem',
    fontWeight: '600',
    letterSpacing: '0.5px',
    lineHeight: 2.5,
    textTransform: 'uppercase',
  },
  caption: {
    fontSize: '0.75rem',
    fontWeight: '400',
    lineHeight: 1.66,
  },
  h1: {
    fontWeight: '600',
    fontSize: '3.5rem',
    letterSpacing: '-0.02em',
    lineHeight: 1.375,
  },
  h2: {
    fontWeight: '600',
    fontSize: '3rem',
    letterSpacing: '-0.02em',
    lineHeight: 1.375,
  },
  h3: {
    fontWeight: '600',
    fontSize: '2.25rem',
    letterSpacing: '-0.02em',
    lineHeight: 1.375,
  },
  h4: {
    fontWeight: '600',
    fontSize: '2rem',
    letterSpacing: '-0.02em',
    lineHeight: 1.375,
  },
  h5: {
    fontWeight: '600',
    fontSize: '1.5rem',
    letterSpacing: '-0.02em',
    lineHeight: 1.375,
  },
  h6: {
    fontWeight: '600',
    fontSize: '1.125rem',
    letterSpacing: '-0.02em',
    lineHeight: 1.375,
  },
};

export const themeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1200,
      xl: 1920,
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          '&.MuiAutocomplete-option[aria-selected="true"]: hover': {
            background: actionColors.hover,
          },
          '&.MuiAutocomplete-option[aria-selected="true"]': {
            background: 'transparent',
          },
          '&.MuiAutocomplete-option.Mui-focusVisible': {
            background: actionColors.hover,
          },
          '&.MuiAutocomplete-option.Mui-focusVisible[aria-selected="true"]': {
            background: actionColors.hover,
          },
          '&.MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
            background: actionColors.hover,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: neutral[500],
          color: '#FFFFFF',
          fontSize: 14,
          fontWeight: 600,
          letterSpacing: 0,
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          marginLeft: '4px',
          marginRight: '4px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
      },
    },
    MuiFilledInput: {
      styleOverrides: filledInputStyleOverrides,
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      // @ts-expect-error - Typescript doesn't recognize the buttonStyleOverrides object as valid
      styleOverrides: buttonStyleOverrides,
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '16px 24px',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '32px 24px',
          '&:last-child': {
            paddingBottom: '32px',
          },
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6',
        },
        subheaderTypographyProps: {
          variant: 'body2',
        },
      },
      styleOverrides: {
        root: {
          padding: '32px 24px',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          scrollBehavior: 'smooth',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
          overflowY: 'scroll',
        },
        body: {
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
        },
        '#__next': {
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        },
        '#nprogress': {
          pointerEvents: 'none',
        },
        '#nprogress .bar': {
          backgroundColor: primaryColors.main,
          height: 3,
          left: 0,
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: 2000,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '32px',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          marginRight: '16px',
          '&.MuiListItemIcon-root': {
            minWidth: 'unset',
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          '& fieldset': {
            borderRadius: '8px',
          },
        },
        input: {
          fontWeight: 500,
        },
        notchedOutline: {
          borderColor: dividerColor,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        elevation: 16,
      },
      styleOverrides: {
        paper: {
          borderColor: dividerColor,
          borderStyle: 'solid',
          borderWidth: 1,
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        switchBase: {
          color: neutral[500],
        },
        track: {
          backgroundColor: neutral[400],
          opacity: 1,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderColor: dividerColor,
          borderStyle: 'solid',
          borderWidth: 1,
          borderRadius: '4px',
          boxShadow: 'none',
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          color: textColors.charcoal,
          ':hover': {
            color: textColors.charcoal,
            background: 'rgba(0, 48, 212, 0.08)',
          },
          '&.Mui-selected, &.Mui-selected:hover': {
            color: primaryColors.contrastText,
            background: primaryColors.main,
          },
          borderRadius: '8px',
        },
        sizeLarge: {
          paddingTop: '22px',
          paddingBottom: '22px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '15px 16px',
          borderBottom: `1px solid ${dividerColor}`,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-hover': {
            cursor: 'pointer',
            ':hover': {
              backgroundColor: 'rgba(72, 109, 229, 0.08) !important',
            },
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
          '& .MuiTableCell-root': {
            borderBottom: 'none',
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: 1,
            letterSpacing: 0.5,
            textTransform: 'uppercase',
          },
          '& .MuiTableCell-paddingCheckbox': {
            paddingTop: 4,
            paddingBottom: 4,
          },
          backgroundColor: neutral[100],
          '.MuiTableCell-root': {
            color: neutral[700],
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: neutral[0],
          color: textColors.primary,
          border: 'solid',
          borderRadius: 8,
          borderWidth: 1,
          borderColor: dividerColor,
          padding: baseMuiTheme.spacing(2),
          boxShadow: '0px 1px 6px rgba(100, 116, 139, 0.12)',
          ...typography.body2,
        },
        arrow: {
          color: neutral[0],
          '&::before': {
            border: 'solid',
            borderWidth: 1,
            borderColor: dividerColor,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          '&.MuiChip-filledDefault': {
            backgroundColor: neutral[200],
            '& .MuiChip-deleteIcon': {
              color: neutral[400],
            },
            '& .MuiChip-deleteIcon:hover': {
              color: neutral[600],
            },
          },
          '&.MuiChip-outlinedDefault': {
            borderColor: neutral[200],
            '& .MuiChip-deleteIcon': {
              color: neutral[300],
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: textColors.secondary,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderColor: dividerColor,
          borderStyle: 'solid',
          borderWidth: 1,
        },
      },
    },
  },
  palette: {
    action: actionColors,
    background: backgroundColors,
    divider: dividerColor,
    error: errorColors,
    info: infoColors,
    mode: 'light',
    // @ts-expect-error - Mui v5 doesn't recognize the neutral object as a valid palette color
    neutral,
    primary: primaryColors,
    secondary: secondaryColors,
    success: successColors,
    text: textColors,
    warning: warningColors,
  },
  shadows,
  direction: 'ltr',
  typography,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
};

type ThemeConfig = {
  direction?: Direction;
  responsiveFontSizes?: boolean;
};

export const createTheme = (config: ThemeConfig): Theme => {
  let theme = createMuiTheme(themeOptions, {
    direction: config.direction,
  });

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
