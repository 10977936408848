// eslint-disable-next-line no-restricted-imports
import { Theme as ThemeBase, useTheme as useThemeBase } from '@mui/material';
import { Neutral } from 'shared/theme';

// Declare our own theme properties
export type Theme = Omit<ThemeBase, 'palette'> & {
  palette: ThemeBase['palette'] & {
    background: {
      contrast: string;
    };
    neutral: Neutral;
    text: {
      charcoal: string;
      primary: string;
      secondary: string;
    };
    info: {
      alertBackground: string;
      alertContent: string;
    };
  };
};

export function useTheme(): Theme {
  const theme = useThemeBase<Theme>();
  return theme;
}
