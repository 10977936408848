import { useEffect, useState } from 'react';

export default function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  // Window is undefined during SSR, so we use useEffect to only run this code in the browser
  useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)) {
      setIsMobile(true);
    }
  }, []);

  return isMobile;
}
