import type { JwtPayload } from 'jwt-decode';
import type { ApolloClient } from '@apollo/client';
import { jwtDecode } from 'jwt-decode';
import { apiHost } from 'shared/api/api-host';
import { fetchCurrentUser, fetchCurrentUserWithoutCaching } from 'shared/api/user-api';
import { AuthFactor, LoginData } from 'shared/utils/savvy-auth';
import { JWTContextUser } from 'shared/api/user-api';
import { LocalStorage } from 'shared/utils/local-storage';
import { Platform } from 'react-native';

const GENERIC_ERROR_MESSAGE = 'Something went wrong. Please try again later.';
const RATE_LIMIT_LOGIN_ERROR_MESSAGE = 'Too many failed log in attempts. Please try again later.';

const users: JWTContextUser[] = [];

function getJwtFromResponse(response: Response): string {
  const authorization = response.headers.get('Authorization');
  if (!authorization) {
    return '';
  }
  const token = authorization.replace('Bearer ', '');
  return token;
}

async function authFetch(url: string, data: LoginData): Promise<Response> {
  return await fetch(url, {
    method: 'POST',
    headers: {
      'Access-Control-Expose-Headers': 'Authorization',
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...data.payload }),
  }).catch((error) => {
    switch (data.type) {
      case AuthFactor.First:
        console.error(`JWT / Auth Error - [Auth Api] with email: [${data.payload.email}] `, error);
        break;
      case AuthFactor.Second:
        console.error(`JWT / Auth Error - [Auth Api] with otp attempt: [${data.payload.otp_attempt}] `, error);
        break;
    }
    throw new Error(GENERIC_ERROR_MESSAGE);
  });
}

async function getCurrentUser(client: ApolloClient<object>, jwt: string) {
  const { sub } = jwtDecode<JwtPayload>(jwt);
  const existingUser = users.find((user) => user?.id === sub);
  if (existingUser) {
    return existingUser;
  }

  let user = null;
  if (Platform.OS === 'web') {
    user = await fetchCurrentUser(client);
  } else {
    user = await fetchCurrentUserWithoutCaching();
  }
  if (user == null) {
    return;
  }
  users.push(user);
  return user;
}

async function handleErrorResponse(response: Response, fallbackMessage?: string): Promise<string | undefined> {
  if (response.status === 429) {
    return RATE_LIMIT_LOGIN_ERROR_MESSAGE;
  }
  if (response.ok) {
    return;
  }
  if (response.status >= 400 && response.status <= 499) {
    type JsonReponseType = {
      status?: { message?: string };
      error?: string;
    };
    const jsonResponse = (await response.json()) as JsonReponseType;
    return jsonResponse.status?.message || jsonResponse.error || fallbackMessage || GENERIC_ERROR_MESSAGE;
  }
  return GENERIC_ERROR_MESSAGE;
}

type LoginResponse = {
  err?: string;
  jwt: string;
};

class AuthApi {
  client: ApolloClient<object>;
  constructor(client: ApolloClient<object>) {
    this.client = client;
  }
  // eslint-disable-next-line class-methods-use-this -- Unsure which syntax we should use
  async login(data: LoginData): Promise<LoginResponse> {
    const response = await authFetch(`${apiHost}/login`, data);
    const err = await handleErrorResponse(response, 'Please check your email and password.');
    const jwt = getJwtFromResponse(response);
    await LocalStorage.setItem('accessToken', jwt);
    return { err, jwt };
  }

  async me(jwt: string) {
    try {
      const user = await getCurrentUser(this.client, jwt);

      if (!user) {
        throw new Error('Invalid authorization token. You may need to clear your cache.');
      }
      return user;
    } catch (err) {
      console.error('JWT / Auth Error - failed to get current user: ', err);
      throw new Error('Internal server error');
    }
  }

  async logout() {
    const jwt = await LocalStorage.getItem('accessToken');
    await LocalStorage.removeItem('accessToken');
    return await fetch(`${apiHost}/logout`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${jwt}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this -- Unsure which syntax we should use
  reset(): void {
    users.splice(0, users.length);
  }
}

export const createAuthApi = (client: ApolloClient<object>) => {
  return new AuthApi(client);
};
