const INVITE_AUTH_SCHEME = 'Invite';
const RESET_PASSWORD_AUTH_SCHEME = 'ResetPassword';
import { ApolloError } from '@apollo/client';
import { NextRouter } from 'next/router';
import { toast } from 'shared/x-components/x-toast';
import { TaskTypeName } from 'shared/generated/deprecated_please_do_not_import_from_here_even_for_enums/graphql';
import { XRouter } from 'shared/hooks/use-router';
import { LOGIN_CONTEXT_PARAM } from 'shared/utils/routing';

export function getAuthHeader(router: XRouter | NextRouter) {
  const resetPasswordToken = router.query['resetPasswordToken'];
  const createPasswordToken = router.query['createPasswordToken'];
  const inviteToken = router.query['inviteToken'];
  const loginContext = getLoginContext(router);
  if (resetPasswordToken && typeof resetPasswordToken === 'string') {
    return `${RESET_PASSWORD_AUTH_SCHEME} ${resetPasswordToken}`;
  } else if (createPasswordToken && typeof createPasswordToken === 'string') {
    return `${RESET_PASSWORD_AUTH_SCHEME} ${createPasswordToken}`;
  } else if (inviteToken && typeof inviteToken === 'string') {
    return `${INVITE_AUTH_SCHEME} ${inviteToken}`;
  } else if (loginContext && typeof loginContext === 'string') {
    return `${INVITE_AUTH_SCHEME} ${loginContext.slice(loginContext.indexOf('_') + 1)}`;
  }

  return '';
}

export function getLoginContext(router: XRouter | NextRouter) {
  return router.query[LOGIN_CONTEXT_PARAM];
}

export const handleInviteErrors = async (router: NextRouter, taskType: TaskTypeName, error?: ApolloError) => {
  if (error) {
    const errorMessage = error.graphQLErrors[0]?.message;
    const { inviteToken } = router.query;
    const returnToLoginUrl = `/login?returnUrl=${window.encodeURIComponent(
      window.location.pathname + window.location.search
    )}`;
    if (inviteToken && typeof inviteToken !== 'string') {
      await router.replace('/invalid-invite-token-dialog');
    } else if (errorMessage?.includes('already activated')) {
      toast.error('Please log in with your existing Savvy account!');
      await router.replace(returnToLoginUrl);
    } else if (errorMessage?.includes('expired')) {
      await router.replace(`/refresh-invite-token-dialog?taskType=${taskType}&inviteToken=${inviteToken}`);
    } else if (errorMessage?.includes('Bad Token')) {
      await router.replace('/invalid-invite-token-dialog');
    } else if (errorMessage?.includes('is not a client')) {
      toast.error('Please log out of your Savvy account to view this page.');
    } else {
      await router.replace(returnToLoginUrl);
    }
  }
};
