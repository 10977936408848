import { ApolloClient, ApolloQueryResult, QueryOptions } from '@apollo/client';
import { Platform } from 'react-native';
import { ResultOf, fetchQueryWithoutCaching, graphql } from 'shared/gql';
import { setSalesforceAccessToken } from 'shared/utils/salesforce';
import { LocalStorage } from 'shared/utils/local-storage';

export const CurrentUserQuery = graphql(/* GraphQL */ `
  query CurrentUser {
    currentUser {
      user {
        email
        phoneNumber
        firstName
        id
        lastName
        role
        addresses {
          id
        }
        confirmationStatus
        clientStage
        profileType
        profileId
        profilePicUrl
        isPrimaryClient
        otpRequiredForLogin
        personalPortfolioId
      }
      salesforceAccessToken
    }
  }
`);

type CurrentUser = ResultOf<typeof CurrentUserQuery>['currentUser'];
export type JWTContextUser = Partial<CurrentUser['user']>;

function handleSalesforceAccessToken(currentUser: ResultOf<typeof CurrentUserQuery>['currentUser']) {
  if (currentUser.salesforceAccessToken) {
    setSalesforceAccessToken(currentUser.salesforceAccessToken);
  }
}

async function currentUserQueryPromise(
  client: ApolloClient<object>,
  bypassCache?: boolean
): Promise<ApolloQueryResult<ResultOf<typeof CurrentUserQuery>>> {
  const options: QueryOptions = { query: CurrentUserQuery };

  if (bypassCache) {
    options.fetchPolicy = 'network-only';
  }

  return await client.query(options);
}

export async function fetchCurrentUser(client: ApolloClient<object>, bypassCache?: boolean): Promise<JWTContextUser> {
  const asyncAccessToken = await LocalStorage.getItem('accessToken');
  // This gql query used by this function requires the access token to be set in localstorage!
  if (Platform.OS === 'web' && typeof window !== 'undefined') {
    // eslint-disable-next-line no-prototype-builtins
    if (!window.localStorage.hasOwnProperty('accessToken')) {
      throw new Error('Access token is not found');
    }
  } else if (Platform.OS !== 'web' && asyncAccessToken == null) {
    throw new Error('Access token is not found');
  }

  const rawData = await currentUserQueryPromise(client, bypassCache);

  handleSalesforceAccessToken(rawData.data.currentUser);

  return rawData.data.currentUser.user;
}

export async function fetchCurrentUserWithoutCaching() {
  const data = await fetchQueryWithoutCaching(CurrentUserQuery);
  return data?.currentUser.user;
}
