export default function GradientSavvyLogo() {
  return (
    <svg viewBox="0 0 655 180" fill="none" xmlns="http://www.w3.org/2000/svg" width="100%" preserveAspectRatio="none">
      <defs>
        <linearGradient id="lingrad" x1="0" y1="0" x2="1024" y2="0" gradientUnits="userSpaceOnUse">
          <stop offset="0%" stopColor="#325288" />
          <stop offset="30%" stopColor="#114E60" />
          <stop offset="60%" stopColor="#F5CEBE" />
          <stop offset="80%" stopColor="#F4EEE8" />
        </linearGradient>
      </defs>
      <g clipPath="url(#clip0_480_9)" fill="url(#lingrad)">
        <path d="M53.9054 58.6587C48.4517 56.4163 42.9564 54.2847 37.7242 51.5855C31.0523 48.1388 24.4635 43.3495 21.9304 36.2762C18.6083 27.0021 23.8268 15.97 32.4919 11.2499C41.157 6.52975 52.189 7.58174 60.7988 12.3988C69.4085 17.2158 75.762 25.341 80.1499 34.1722C80.7728 34.1722 81.3957 34.1861 82.0047 34.1999C82.0047 25.6594 82.0047 17.1327 82.0047 8.59221C72.3568 4.93792 62.3768 1.92036 52.0921 1.11753C41.8075 0.314691 31.163 1.82347 22.1242 6.79275C7.29943 14.9457 -0.659724 32.6911 3.86661 49.1631C4.61408 51.9038 5.73528 54.5615 7.20253 56.9977C12.0888 65.1229 20.4632 70.5352 29.0591 74.5493C37.6549 78.5635 46.763 81.5119 54.9713 86.2735C59.8298 89.0835 64.4392 92.6408 67.3183 97.4579C70.6543 103.064 71.291 110.359 68.3842 116.214C63.9824 125.073 52.895 128.685 43.0118 128.049C25.0726 126.914 8.48984 113.833 3.2022 96.6412C2.45473 96.655 1.7211 96.6827 0.973633 96.6966C1.40273 107.853 1.81799 118.996 2.2471 130.153C14.788 135.33 28.0624 139.399 41.6276 139.344C55.1928 139.288 69.187 134.679 78.3919 124.713C83.9703 118.664 87.583 110.857 88.6627 102.69C89.6317 95.34 88.5105 87.6439 84.9392 81.1658C80.3714 72.8745 72.2184 67.1439 63.7194 62.9774C60.5081 61.3994 57.2137 60.0014 53.9054 58.6449V58.6587Z" />
        <path d="M291.087 39.2798V41.0239C289.26 41.7852 287.516 42.8926 285.827 44.3183C284.138 45.7441 282.408 47.8757 280.664 50.741C278.92 53.6063 276.913 57.316 274.712 61.9115C272.497 66.5209 269.881 72.3761 266.905 79.5047L241.74 138.776H237.837L209.544 71.3379C206.665 64.6107 204.173 59.1708 202.028 55.0182C199.868 50.8794 197.917 47.7096 196.172 45.5087C194.428 43.3079 192.629 41.8268 190.802 41.0378V39.2937H231.774V41.0378C227.995 42.3389 226.127 44.8443 226.127 48.6093C226.127 49.7859 226.251 50.9625 226.514 52.2083C226.777 53.4402 227.234 54.9905 227.871 56.8592C228.507 58.7278 229.435 61.1779 230.598 64.1539L249.132 110.206L260.842 79.3109C263.431 72.4314 265.23 67.1438 266.213 63.3788C267.182 59.6137 267.68 56.4993 267.68 54.0492C267.68 48.0972 264.109 43.737 256.953 41.0378V39.2937H291.101V39.266L291.087 39.2798Z" />
        <path d="M396.023 39.2798V41.0239C394.196 41.7852 392.452 42.8926 390.763 44.3183C389.075 45.7441 387.344 47.8757 385.6 50.741C383.856 53.6063 381.849 57.316 379.648 61.9115C377.434 66.5209 374.817 72.3761 371.841 79.5047L346.691 138.776H342.787L314.494 71.3379C311.615 64.6107 309.123 59.1708 306.978 55.0182C304.818 50.8794 302.867 47.7096 301.123 45.5087C299.379 43.3079 297.579 41.8268 295.752 41.0378V39.2937H336.724V41.0378C332.945 42.3389 331.077 44.8443 331.077 48.6093C331.077 49.7859 331.201 50.9625 331.464 52.2083C331.727 53.4402 332.184 54.9905 332.821 56.8592C333.458 58.7278 334.385 61.1779 335.548 64.1539L354.082 110.206L365.792 79.3109C368.381 72.4314 370.18 67.1438 371.163 63.3788C372.132 59.6137 372.63 56.4993 372.63 54.0492C372.63 48.0972 369.059 43.737 361.903 41.0378V39.2937H396.051V39.266L396.023 39.2798Z" />
        <path d="M466.826 39.2799V41.024C473.982 43.6124 477.553 47.9588 477.553 54.0355C477.553 56.4855 477.069 59.6138 476.086 63.365C475.117 67.13 473.262 72.4453 470.521 79.2971L459.019 110.013L440.484 64.1401C439.308 61.0257 438.408 58.5618 437.757 56.7485C437.121 54.9352 436.65 53.4264 436.401 52.1668C436.138 50.9348 436.013 49.7306 436.013 48.5679C436.013 44.8028 437.882 42.2836 441.661 40.9963V39.2522H400.688V40.9963C402.516 41.7576 404.301 43.2664 406.059 45.4673C407.803 47.6681 409.755 50.838 411.914 54.9767C414.074 59.1155 416.565 64.5692 419.431 71.2964L446.948 136.409L445.26 140.022L444.235 142.195C443.903 142.943 443.599 143.676 443.239 144.396C442.574 145.753 441.855 147.081 440.983 148.327C436.359 154.999 427.279 157.255 420.289 152.674C419.514 152.161 418.794 151.594 418.157 150.957C417.41 150.196 416.745 149.324 416.081 148.507C410.669 149.781 408.246 158.376 411.098 163.166C413.382 167 417.908 168.966 422.448 169.104C423.555 169.132 424.663 169.049 425.729 168.869C434.172 167.415 440.207 161.02 444.443 153.781L444.526 153.629L444.761 153.2L444.941 152.84L445.094 152.535L445.246 152.231L445.398 151.926L445.813 151.109L446.298 150.113L446.782 149.061L447.378 147.801L449.274 143.704L450.963 140.05L451.655 138.569L476.806 79.4909C479.809 72.3623 482.398 66.4933 484.613 61.8977C486.827 57.2883 488.793 53.6064 490.565 50.8241C492.309 48.0419 494.025 45.9102 495.728 44.4014C497.416 42.9203 499.16 41.7714 500.988 40.9963V39.2522H466.839L466.812 39.2799H466.826Z" />
        <path d="M179.838 69.5798C178.523 61.5515 176.391 55.0319 173.388 50.0488C170.384 45.0657 166.328 41.4252 161.193 39.1551C156.057 36.885 149.593 35.75 141.786 35.75C133.979 35.75 126.768 36.8989 120.04 40.1933C115.832 42.2696 111.901 45.301 109.77 49.4813C108.69 51.6545 108.095 54.1875 108.842 56.513C109.59 58.8385 111.597 60.6241 113.895 61.3992C116.206 62.1605 118.767 62.0083 121.079 61.247C121.162 55.0596 123.238 48.3877 128.166 44.3459C132.125 41.1207 137.966 40.7193 142.741 42.5603C144.458 43.2247 146.077 44.249 147.337 45.6055C149.801 48.2632 151.988 51.433 153.801 58.2848C136.886 66.7007 124.567 75.1305 116.829 83.5464C109.105 91.9624 105.229 101.112 105.229 110.954C105.229 116.781 106.461 121.792 108.925 126.014C111.389 130.235 114.905 133.502 119.445 135.828C123.985 138.153 129.273 139.33 135.253 139.33C142.132 139.33 148.14 137.516 153.192 133.876C158.272 130.249 162.48 124.879 165.871 117.736C167.311 124.865 169.65 129.986 172.903 132.976C176.156 135.966 181.167 137.447 187.922 137.447H196.697V135.703C192.669 134.679 189.915 129.599 188.351 120.671L179.783 69.5522L179.838 69.5798ZM157.192 123.993C153.677 126.318 150.105 127.495 146.465 127.495C140.734 127.495 136.249 125.418 133.01 121.28C127.391 114.096 127.238 103.451 129.107 94.9107C130.976 86.3702 135.571 78.2173 142.077 72.1821C146.008 68.5555 150.631 65.6626 154.991 62.6173C155.504 64.6936 165.207 114.276 165.207 114.276C163.255 117.778 160.708 121.64 157.192 123.965V123.993Z" />
      </g>
      <defs>
        <clipPath id="clip0_480_9">
          <rect width="500.015" height="168.18" fill="white" transform="translate(0.986328 0.90979)" />
        </clipPath>
      </defs>
    </svg>
  );
}
