import { useMediaQuery } from '@mui/material';
import useIsMobile from 'shared/hooks/use-is-mobile';
import { useTheme } from 'shared/hooks/use-theme';

export function useIsSmallScreen() {
  const theme = useTheme();
  const isMobile = useIsMobile();

  return useMediaQuery(theme.breakpoints.down('sm')) || isMobile;
}
