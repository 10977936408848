import { apiHost } from 'shared/api/api-host';
import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';

import { setContext } from '@apollo/client/link/context';
import { SALESFORCE_CLIENT_NAME } from 'shared/utils/salesforce';
import { getSalesforceAccessToken } from 'shared/utils/salesforce';
import TimeoutLink from 'apollo-link-timeout';
import { LocalStorage } from 'shared/utils/local-storage';

const timeoutLink = new TimeoutLink(30000);

const httpLink = createHttpLink({
  uri: `${apiHost}/graphql`,
  credentials: 'include',
});

const GENERAL_AUTH_SCHEME = 'Bearer';

const authLink = setContext(async (_, { headers }) => {
  // Get the authentication token from local storage if it exists
  const accessToken = await LocalStorage.getItem('accessToken');

  // Return the headers to the context so httpLink can read them
  let authHeader = null;
  if (accessToken) {
    authHeader = `${GENERAL_AUTH_SCHEME} ${accessToken}`;
  }

  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    headers: {
      ...headers,
      ...(authHeader && { authorization: authHeader }),
    },
  };
});

function sfApiClientUrl() {
  if (process.env['NEXT_PUBLIC_VERCEL_ENV'] === 'development') {
    return 'https://savvywealthinc--delegate.sandbox.my.salesforce.com/services/data/v56.0/';
  }
  return 'https://savvywealthinc.my.salesforce.com/services/data/v56.0/';
}
function sfRestLink() {
  return new RestLink({
    uri: sfApiClientUrl(),
    headers: {
      Authorization: `Bearer ${getSalesforceAccessToken()}`,
    },
    responseTransformer: (response: unknown) => {
      // @ts-expect-error response not typed
      if (typeof response.json !== 'function') {
        return {};
      }

      // @ts-expect-error response not typed
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      return response.json().then((data: unknown) => {
        // @ts-expect-error not typed
        if (data.records) {
          // @ts-expect-error not typed
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const records: unknown[] = data.records;
          // NextRecordsUrl is a URL to the next page of records for a paginated response
          // @ts-expect-error not typed
          if (data.nextRecordsUrl) {
            const lastRecord = records[records.length - 1];
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const updatedLastRecord = {
              // @ts-expect-error not typed
              ...lastRecord,
              // @ts-expect-error not typed
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              nextRecordsUrl: data.nextRecordsUrl,
            };

            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return [...records.slice(0, records.length - 1), updatedLastRecord];
          }
          return records;
          // @ts-expect-error not typed
        } else if (data.fields) {
          // @ts-expect-error not typed
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return data.fields;
        }
        return data;
      });
    },
  });
}

let apolloClient: ApolloClient<unknown> | null;
export const createApolloClient = () => {
  if (apolloClient) return apolloClient;
  apolloClient = new ApolloClient({
    connectToDevTools: true,
    link: ApolloLink.split(
      (operation) => {
        return operation.getContext()['clientName'] === SALESFORCE_CLIENT_NAME; // Routes the query to the proper client
      },
      timeoutLink.concat(sfRestLink()),
      timeoutLink.concat(authLink.concat(httpLink))
    ),
    cache: new InMemoryCache({
      typePolicies: {
        SalesforceAccount: {
          keyFields: ['Id'],
          fields: {
            Contacts: {
              merge(existing, incoming, { mergeObjects }) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return mergeObjects(existing, incoming);
              },
            },
          },
        },
        SalesforceContact: {
          keyFields: ['Id'],
        },
      },
    }),
  });
  return apolloClient;
};
