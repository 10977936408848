export const SERVER_PORT = 4000;
export const CLIENT_PORT = 3000;

declare const process: {
  env: {
    EXPO_PUBLIC_NGROK_URL: string | null | undefined;
    NEXT_PUBLIC_VERCEL_ENV: 'development' | 'production' | 'test' | null | undefined;
    NODE_ENV: 'development' | 'production' | 'test' | null | undefined;
  };
};

const vercelEnvToMonorailsApiHost: Record<string, string> = {
  development:
    process.env.EXPO_PUBLIC_NGROK_URL != null ? process.env.EXPO_PUBLIC_NGROK_URL : `http://localhost:${SERVER_PORT}`,
  preview: 'https://api.savvywealth.com',
  production: 'https://api.savvywealth.com',
};

const vercelEnv = process.env.NEXT_PUBLIC_VERCEL_ENV;
export const apiHost = vercelEnvToMonorailsApiHost[vercelEnv ?? process.env.NODE_ENV ?? 'development'];

const vercelEnvToVercelApiHost: Record<string, string> = {
  development: `http://localhost:${CLIENT_PORT}/api`,
  preview: 'https://app.savvywealth.com/api',
  production: 'https://app.savvywealth.com/api',
};

export const vercelApiHost = vercelEnvToVercelApiHost[vercelEnv ?? 'development'];
